import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavTopBar from '../nav-top-bar/nav-top-bar';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import Loader from '../../components/Loader';
import { generateSubjectReportDD } from './subject-report-layput';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const initial_add_report_data = {
  studentId: null,
  pk1: null,
  mk1: null,
  pk2: null,
  mk2: null,
  extraPoints: null,
  zkOrEk: null,
};

const initial_state = {
  report: null,
  reportData: [],
  students: [],
  addReportRecordData: initial_add_report_data,
  isReportDataEditable: false,
  isReportMarksEditable: false,
  isAllowedToEdit: false,
  isLoading: false,
  institutes: [],
  departments: [],
  specialities: [],
  error: null,
  saveError: null,
};

class SubjectReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial_state };

    const currYear = new Date().getUTCFullYear();
    this.availableYears = Array(currYear - (currYear - 20))
      .fill('')
      .map((v, idx) => currYear - idx);
  }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(
      `${constants.BE_BASE_URL}/subjects-reports/${this.props.match.params.reportId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.token}`,
        },
      }
    )
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('report', data));
          this.setState(stateSetter('reportData', data.reportData));
          this.setState(
            stateSetter(
              'isAllowedToEdit',
              data.teachers.find(
                teacher => teacher.teacherId === this.props.auth.login
              )
                ? true
                : false
            )
          );
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/students`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('students', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
    fetch(`${constants.BE_BASE_URL}/institutes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('institutes', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/departments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('departments', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/specialities`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('specialities', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  handleReportCardClick(e, reportId) {
    this.props.history.push(`subjects-reports/${reportId}`);
  }

  getMainTeacherDataStr(mainTeacherId, teachers) {
    const mainTeacher = teachers.find(
      teacher => teacher.teacherId === mainTeacherId
    );

    return `${mainTeacher.lastName} ${mainTeacher.firstName} ${mainTeacher.middleName}`;
  }

  getOtherTeachersDataStr(mainTeacherId, teachers) {
    const otherTeachers = teachers.filter(
      teacher => teacher.teacherId !== mainTeacherId
    );

    return otherTeachers
      .map(
        teacher =>
          `${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`
      )
      .join(', ');
  }

  generateAndDownloadSubjectReport(event, reportData) {
    const fileName = `${reportData.subjectName}_${reportData.educationStartYear}-${reportData.educationEndYear}_Cеместер№${reportData.educationSemester}`;
    pdfMake.createPdf(generateSubjectReportDD(reportData)).download(fileName);
  }

  generateAndOpenSubjectReport(event, reportData) {
    pdfMake.createPdf(generateSubjectReportDD(reportData)).open();
  }

  handleReportDataChange(dataType, value) {
    const { report } = this.state;

    report[dataType] = value;

    this.setState(stateSetter('report', report));
  }

  handleReportDataMarksChange(id, dataType, value) {
    const { reportData } = this.state;

    const currentReportDataIndex = reportData.findIndex(
      record => record.id === id
    );

    reportData[currentReportDataIndex][dataType] = value;

    this.setState(stateSetter('reportData', reportData));
  }

  handleDeleting(e) {
    this.setState(stateSetter('isLoading', true));

    fetch(
      `${constants.BE_BASE_URL}/subjects-reports/${this.state.report.reportId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.token}`,
        },
      }
    )
      .then(async res => {
        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('isLoading', false));
          this.props.history.push(`/my-reports`);
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  handleEditing(editType) {
    this.setState(stateSetter(editType, true));
  }

  handleSave() {
    const {
      isReportDataEditable,
      isReportMarksEditable,
      institutes,
      departments,
      specialities,
      reportData,
      report,
    } = this.state;

    if (isReportMarksEditable) {
      let isValid = true;
      reportData.forEach(row => {
        Object.keys(row).forEach(key => {
          if (key !== 'student' && row[key] !== null && isNaN(row[key])) {
            this.setState(
              stateSetter(
                'saveError',
                `Поля з оцінками мають бути заповненими цифрами. Перевірте введені данні та спробуйте знову.`
              )
            );
            isValid = false;
          }
        });
      });

      if (isValid) {
        this.setState(stateSetter('isLoading', true));
        this.setState(stateSetter('saveError', null));
        this.setState(stateSetter('isReportMarksEditable', false));

        fetch(
          `${constants.BE_BASE_URL}/subjects-reports/${report.reportId}/report-data/bulk`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.props.auth.token}`,
            },
            body: JSON.stringify({
              reportData: reportData.map(record => {
                return {
                  ...record,
                  pk1: record.pk1 ? Number(record.pk1) : null,
                  pk2: record.pk2 ? Number(record.pk2) : null,
                  mk1: record.mk1 ? Number(record.mk1) : null,
                  mk2: record.mk2 ? Number(record.mk2) : null,
                  extraPoints: record.extraPoints
                    ? Number(record.extraPoints)
                    : null,
                  zkOrEk: record.zkOrEk ? Number(record.zkOrEk) : null,
                };
              }),
            }),
          }
        )
          .then(async res => {
            const data = await res.json();

            if (String(res.status).startsWith(2)) {
              window.location.reload(false);
            }

            if (String(res.status).startsWith(4)) {
              this.setState(stateSetter('saveError', data.message));
              this.setState(stateSetter('isLoading', false));
            }

            if (String(res.status).startsWith(5)) {
              this.setState(stateSetter('isLoading', false));
              this.setState(
                stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
              );
            }
          })
          .catch(err => {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
            );
          });
      }
    }

    if (isReportDataEditable) {
      this.setState(stateSetter('saveError', null));
      this.setState(stateSetter('isReportDataEditable', false));
      this.setState(stateSetter('isLoading', true));

      fetch(`${constants.BE_BASE_URL}/subjects-reports/${report.reportId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.token}`,
        },
        body: JSON.stringify({
          ...report,
          instituteId: report
            ? institutes.find(
                i => i.instituteId === report.institute.instituteId
              ).instituteId
            : null,
          departmentId: report
            ? departments.find(
                i => i.departmentId === report.department.departmentId
              ).departmentId
            : null,
          specialityId: report
            ? specialities.find(
                i => i.specialtyId === report.specialty.specialtyId
              ).specialtyId
            : null,

          groupId: report.group.groupId,
          allAssignedTeachersIds: report.teachers.map(
            teacher => teacher.teacherId
          ),
          educationStartYear: Number(report.educationStartYear),
          educationEndYear: Number(report.educationStartYear) + 1,
          educationSemester: Number(report.educationSemester),
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            this.setState(stateSetter('saveError', data.message));
            this.setState(stateSetter('isLoading', false));
          }

          if (String(res.status).startsWith(5)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
  }

  handleAddReportDataChange(dataType, value) {
    this.setState(
      stateSetter('addReportRecordData', {
        ...this.state.addReportRecordData,
        [dataType]: value === '' ? null : value,
      })
    );
  }

  handleAddReportDataButton(e) {
    const { addReportRecordData } = this.state;

    let isValid = true;
    Object.keys(addReportRecordData).forEach(key => {
      if (
        key !== 'studentId' &&
        addReportRecordData[key] !== null &&
        isNaN(addReportRecordData[key])
      ) {
        this.setState(
          stateSetter(
            'error',
            `Поля з оцінками мають бути заповненими цифрами. Перевірте введені данні та спробуйте знову.`
          )
        );
        isValid = false;
      }

      if (key === 'studentId' && addReportRecordData[key] === null) {
        this.setState(
          stateSetter(
            'error',
            `Для того щоб додати новий запис, виберіть студента.`
          )
        );
        isValid = false;
      }
    });

    if (isValid) {
      this.setState(stateSetter('error', null));
      this.setState(stateSetter('isLoading', true));

      fetch(
        `${constants.BE_BASE_URL}/subjects-reports/${this.state.report.reportId}/report-data`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.auth.token}`,
          },
          body: JSON.stringify({
            studentId: addReportRecordData.studentId,
            pk1: Number(addReportRecordData.pk1),
            pk2: Number(addReportRecordData.pk2),
            mk1: Number(addReportRecordData.mk1),
            mk2: Number(addReportRecordData.mk2),
            extraPoints: Number(addReportRecordData.extraPoints),
            zkOrEk: Number(addReportRecordData.zkOrEk),
          }),
        }
      )
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            this.setState(stateSetter('error', data.message));
            this.setState(stateSetter('isLoading', false));
          }

          if (String(res.status).startsWith(5)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
  }

  handleDeleteReportDataButton(e) {
    this.setState(stateSetter('isLoading', true));

    const reportDataId = e.target.value;

    fetch(
      `${constants.BE_BASE_URL}/subjects-reports/${this.state.report.reportId}/report-data/${reportDataId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.token}`,
        },
      }
    )
      .then(async res => {
        if (String(res.status).startsWith(2)) {
          window.location.reload(false);
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  onKeyDownHandler(e) {
    e.target.style.width = (e.target.value.length + 1) * 8.7 + 'px !important';
  }

  render() {
    const {
      report,
      reportData,
      isLoading,
      institutes,
      departments,
      specialities,
      error,
      saveError,
      isAllowedToEdit,
      isReportMarksEditable,
      isReportDataEditable,
    } = this.state;

    const defaultInstitute = report
      ? institutes.find(i => i.instituteId === report.institute.instituteId)
      : null;
    const defaultDepartment = report
      ? departments.find(i => i.departmentId === report.department.departmentId)
      : null;
    const defaulSpecialty = report
      ? specialities.find(i => i.specialtyId === report.specialty.specialtyId)
      : null;

    return (
      <React.Fragment>
        {isLoading ? <Loader /> : <div></div>}

        <header>
          <NavTopBar auth={this.props.auth} />
        </header>
        <main>
          <div className="container mt-5 pt-5 pb-3">
            <h3 className="pb-4 border-bottom">
              Відомість{' '}
              {report
                ? report.reportType === 'zalik'
                  ? '(Залік)'
                  : '(Екзамен)'
                : ''}
            </h3>
          </div>

          <div className="container py-1">
            <button
              type="button"
              className="btn btn-primary"
              disabled={
                isReportDataEditable || isReportMarksEditable ? 'disabled' : ''
              }
              onClick={event =>
                this.generateAndDownloadSubjectReport(event, report)
              }
            >
              Завантажити відомість
            </button>
            <button
              type="button"
              className="btn btn-primary ml-4"
              disabled={
                isReportDataEditable || isReportMarksEditable ? 'disabled' : ''
              }
              onClick={event =>
                this.generateAndOpenSubjectReport(event, report)
              }
            >
              Переглянути відомість
            </button>
            <br />
            <button
              type="button"
              className="btn btn-primary mt-3"
              value="isReportDataEditable"
              disabled={
                !isAllowedToEdit ||
                isReportDataEditable ||
                isReportMarksEditable
                  ? 'disabled'
                  : ''
              }
              onClick={event => this.handleEditing(event.target.value)}
            >
              Редагувати дані відомості
            </button>
            <button
              type="button"
              className="btn btn-primary ml-4 mt-3"
              value="isReportMarksEditable"
              disabled={
                !isAllowedToEdit ||
                isReportDataEditable ||
                isReportMarksEditable
                  ? 'disabled'
                  : ''
              }
              onClick={event => this.handleEditing(event.target.value)}
            >
              Редагувати оцінки
            </button>
            <button
              type="button"
              className="btn btn-danger ml-4 mt-3"
              value="isReportMarksEditable"
              disabled={
                !isAllowedToEdit ||
                isReportDataEditable ||
                isReportMarksEditable
                  ? 'disabled'
                  : ''
              }
              onClick={event => this.handleDeleting(event)}
            >
              Видалити відомість
            </button>
            {isReportDataEditable || isReportMarksEditable ? (
              <button
                type="button"
                className="btn btn-success ml-4 mt-3"
                value="isReportMarksEditable"
                onClick={event => this.handleSave()}
              >
                Зберегти
              </button>
            ) : (
              <div></div>
            )}
            {report ? (
              <form className="mt-3 form" style={{ fontSize: '1.1em' }}>
                <select
                  className="form-control"
                  style={{
                    width: '70%',
                    display: 'inline-block',
                    padding: '5px',
                    marginBottom: '10px',
                    height: '33px',
                  }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  onChange={e =>
                    this.handleReportDataChange('instituteId', e.target.value)
                  }
                >
                  <option>
                    {defaultInstitute ? defaultInstitute.instituteName : ''}
                  </option>

                  {institutes
                    .filter(i => i.instituteId !== report.institute.instituteId)
                    .map(institute => (
                      <option
                        key={institute.instituteId}
                        value={institute.instituteId}
                      >
                        {institute.instituteName}
                      </option>
                    ))}
                </select>
                <br />
                Спеціальність:
                <select
                  className="form-control"
                  style={{
                    width: '50%',
                    display: 'inline-block',
                    padding: '5px',
                    marginLeft: '10px',
                    height: '33px',
                  }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  onChange={e =>
                    this.handleReportDataChange('specialtyId', e.target.value)
                  }
                >
                  <option>
                    {defaulSpecialty ? defaulSpecialty.specialtyName : ''}
                  </option>
                  {specialities
                    .filter(i => i.specialtyId !== report.specialty.specialtyId)

                    .map(specialty => (
                      <option
                        key={specialty.specialtyId}
                        value={specialty.specialtyId}
                      >
                        {specialty.specialtyName}
                      </option>
                    ))}
                </select>
                <br />
                Група:
                <input
                  onChange={e =>
                    this.handleReportDataChange('groupName', e.target.value)
                  }
                  type="text"
                  className="form-control ml-1 mr-3 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '22%' }}
                  disabled
                  defaultValue={`${
                    report.group
                      ? `${report.group.groupName} - ${(function() {
                          const educationYear =
                            new Date().getUTCFullYear() -
                            report.group.groupEducationStartYear;
                          if (report.group.groupEducationType === 'bachelor') {
                            return educationYear > 4
                              ? 4
                              : educationYear < 1
                              ? 1
                              : educationYear;
                          }
                          if (report.group.groupEducationType === 'master') {
                            return educationYear > 2
                              ? 2
                              : educationYear < 1
                              ? 1
                              : educationYear;
                          }
                        })()}${report.group.groupNumber}`
                      : ''
                  } ${
                    report.group.groupEducationType === 'bachelor'
                      ? `${report.group.groupEducationStartYear} - ${report
                          .group.groupEducationStartYear + 4}`
                      : `${report.group.groupEducationStartYear} - ${report
                          .group.groupEducationStartYear + 2}`
                  }`}
                />
                Семестр:
                <select
                  className="form-control ml-1 mt-2 reportDataInput font-weight-bold w-1"
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.educationSemester}
                  style={{ width: '50px' }}
                  onChange={e =>
                    this.handleReportDataChange(
                      'educationSemester',
                      e.target.value
                    )
                  }
                >
                  <option>1</option>
                  <option>2</option>
                </select>{' '}
                <br />
                Кафедра:
                <select
                  className="form-control"
                  style={{
                    width: '50%',
                    display: 'inline-block',
                    padding: '5px',
                    marginLeft: '20px',
                    marginTop: '10px',
                    height: '33px',
                  }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  onChange={e =>
                    this.handleReportDataChange('specialtyId', e.target.value)
                  }
                >
                  <option>
                    {defaultDepartment ? defaultDepartment.departmentName : ''}
                  </option>
                  {departments
                    .filter(
                      i => i.departmentId !== report.department.departmentId
                    )

                    .map(department => (
                      <option
                        key={department.departmentName}
                        value={department.departmentName}
                      >
                        {department.departmentName}
                      </option>
                    ))}
                </select>
                <br />
                <select
                  className="form-control ml-1 mt-2 reportDataInput font-weight-bold w-1"
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.educationStartYear}
                  style={{ width: '75px' }}
                  onChange={e =>
                    this.handleReportDataChange(
                      'educationStartYear',
                      e.target.value
                    )
                  }
                >
                  {this.availableYears.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>{' '}
                -
                <input
                  onChange={e =>
                    this.handleReportDataChange(
                      'educationEndYear',
                      e.target.value
                    )
                  }
                  type="text"
                  className="form-control ml-1 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '50px' }}
                  // disabled={isReportDataEditable ? '' : 'disabled'}
                  disabled
                  defaultValue={report.educationEndYear}
                />{' '}
                навчальний рік <br />
                Дисципліна:
                <input
                  onChange={e =>
                    this.handleReportDataChange('subjectName', e.target.value)
                  }
                  type="text"
                  className="form-control ml-1 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '30%' }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.subjectName}
                />
                <br />
                Лектор:{' '}
                <span className="font-weight-bold">
                  {this.getMainTeacherDataStr(
                    report.mainTeacherId,
                    report.teachers
                  )}
                </span>
                <br />
                Інші викладачі:{' '}
                <span className="font-weight-bold">
                  {this.getOtherTeachersDataStr(
                    report.mainTeacherId,
                    report.teachers
                  )}
                </span>
                <br />
                Дата відомості:
                <input
                  onChange={e =>
                    this.handleReportDataChange('reportDate', e.target.value)
                  }
                  type="date"
                  className="form-control ml-1 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '15%' }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.reportDate}
                />
                <br />
                Дати проведення контрольних заходів{' '}
                {report.reportType === 'zalik' ? 'КЗ' : 'МК'}1:
                <input
                  onChange={e =>
                    this.handleReportDataChange('kzOrMk1Date', e.target.value)
                  }
                  type="text"
                  className="form-control ml-1 mr-3 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '15%' }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.kzOrMk1Date}
                />
                ; {report.reportType === 'zalik' ? 'КЗ' : 'МК'}2
                <input
                  onChange={e =>
                    this.handleReportDataChange('kzOrMk2Date', e.target.value)
                  }
                  type="text"
                  className="form-control ml-1 mr-3 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '15%' }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.kzOrMk2Date}
                />
                ; {report.reportType === 'zalik' ? 'ЗК' : 'ЕК'}
                <input
                  onChange={e =>
                    this.handleReportDataChange('zkOrEkDate', e.target.value)
                  }
                  type="text"
                  className="form-control ml-1 mr-3 mt-2 reportDataInput font-weight-bold w-1"
                  style={{ width: '15%', margin: '0px, 0px' }}
                  disabled={isReportDataEditable ? '' : 'disabled'}
                  defaultValue={report.zkOrEkDate}
                />
              </form>
            ) : (
              <div> </div>
            )}

            <div className="text-center">
              {saveError && <p className="text-danger">{saveError}</p>}
            </div>

            <form>
              <table className="table mt-3 table-bordered text-center">
                <thead>
                  <tr>
                    <th className="align-middle" scope="col">
                      №
                    </th>
                    <th className="align-middle" scope="col">
                      Студент
                    </th>
                    <th className="align-middle" scope="col">
                      ПК1
                    </th>
                    <th className="align-middle" scope="col">
                      МК1
                    </th>
                    <th className="align-middle" scope="col">
                      ПК2
                    </th>
                    <th className="align-middle" scope="col">
                      МК2
                    </th>
                    <th className="align-middle" scope="col">
                      Додаткові
                      <br />
                      бали
                    </th>
                    <th className="align-middle" scope="col">
                      {report && report.reportType === 'zalik' ? 'ЗК' : 'EК'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.length ? (
                    reportData
                      .sort((a, b) => {
                        if (a.student.lastName < b.student.lastName) {
                          return -1;
                        }
                        if (a.student.lastName > b.student.lastName) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">
                              {`${row.student.lastName} ${row.student.firstName} ${row.student.middleName}`}
                            </td>
                            <td>
                              <input
                                onChange={e =>
                                  this.handleReportDataMarksChange(
                                    row.id,
                                    'pk1',
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control reportDataMarkInput"
                                disabled={
                                  isReportMarksEditable ? '' : 'disabled'
                                }
                                defaultValue={row.pk1}
                              />
                            </td>
                            <td>
                              <input
                                onChange={e =>
                                  this.handleReportDataMarksChange(
                                    row.id,
                                    'mk1',
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control reportDataMarkInput"
                                disabled={
                                  isReportMarksEditable ? '' : 'disabled'
                                }
                                defaultValue={row.mk1}
                              />
                            </td>
                            <td>
                              <input
                                onChange={e =>
                                  this.handleReportDataMarksChange(
                                    row.id,
                                    'pk2',
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control reportDataMarkInput"
                                disabled={
                                  isReportMarksEditable ? '' : 'disabled'
                                }
                                defaultValue={row.pk2}
                              />
                            </td>
                            <td>
                              <input
                                onChange={e =>
                                  this.handleReportDataMarksChange(
                                    row.id,
                                    'mk2',
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control reportDataMarkInput"
                                disabled={
                                  isReportMarksEditable ? '' : 'disabled'
                                }
                                defaultValue={row.mk2}
                              />
                            </td>
                            <td>
                              <input
                                onChange={e =>
                                  this.handleReportDataMarksChange(
                                    row.id,
                                    'extraPoints',
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control reportDataMarkInput"
                                disabled={
                                  isReportMarksEditable ? '' : 'disabled'
                                }
                                defaultValue={row.extraPoints}
                              />
                            </td>
                            <td>
                              <input
                                onChange={e =>
                                  this.handleReportDataMarksChange(
                                    row.id,
                                    'zkOrEk',
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control reportDataMarkInput"
                                disabled={
                                  isReportMarksEditable ? '' : 'disabled'
                                }
                                defaultValue={row.zkOrEk}
                              />
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </tbody>
              </table>
              <div className="text-center">
                {error && <p className="text-danger">{error}</p>}
              </div>
            </form>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(SubjectReportPage);
