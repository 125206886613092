import React, { Component } from 'react';
import AuthService from './auth-service';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';

const initial_state = {
  login: '',
  password: '',
  error: null,
  isLoading: false,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial_state };
    this.Auth = new AuthService();
  }

  onSubmit = (event, isInvalid) => {
    if (isInvalid) {
      this.setState({
        error: `Будь-ласка заповніть всі поля!`,
      });
    } else {
      this.setState(stateSetter('isLoading', true));

      const { login, password } = this.state;

      fetch(`${constants.BE_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          teacherId: login,
          password,
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            this.props.auth.authorize(data.token, login);
            this.setState({ ...initial_state });
          }

          if (String(res.status).startsWith(4)) {
            this.setState({ ...initial_state });
            this.setState(stateSetter('error', data.message));
          }

          if (String(res.status).startsWith(5)) {
            this.setState({ ...initial_state });
            this.setState(
              stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState({ ...initial_state });
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
    event.preventDefault();
  };

  handleChange(event, property) {
    return this.setState(stateSetter(property, event.target.value));
  }

  render() {
    const { login, password, error, isLoading } = this.state;

    const isInvalid = login === '' || password === '';

    return (
      <div>
        {isLoading ? (
          <div
            id="spinnerWrap"
            className="d-flex align-items-center justify-content-center text-center"
          >
            <div
              className="spinner-border text-secondary"
              style={{ width: '4rem', height: '4rem' }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="vw-100 vh-100 d-flex align-items-center justify-content-center text-center">
          <div className="form p-4">
            <h1 className="display-6 py-2 text-truncate">Введіть ваші дані:</h1>
            <div className="px-2">
              <form
                className="justify-content-center"
                onSubmit={e => this.onSubmit(e, isInvalid)}
              >
                <div className="form-group">
                  <input
                    type="login"
                    className="form-control"
                    value={login}
                    placeholder="Логін"
                    onChange={event => this.handleChange(event, 'login')}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    placeholder="Пароль"
                    onChange={event => this.handleChange(event, 'password')}
                  />
                </div>
                <div className="text-danger">{error && <p>{error}</p>}</div>
                <button type="submit" className="btn btn-primary">
                  Увійти
                </button>
                <p className="mt-5 mb-3 text-muted">
                  &copy; 2019-2020 by Vitalii Namys
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignInForm;
