export const generateSubjectReportDD = report => {
  const {
    reportType,
    institute,
    specialty,
    department,
    subjectName,
    reportDate,
    kzOrMk1Date,
    kzOrMk2Date,
    zkOrEkDate,
    educationStartYear,
    educationEndYear,
    educationSemester,
    mainTeacherId,
    teachers,
    reportData,
  } = report;

  const mainTeacherData = teachers.find(
    teacher => teacher.teacherId === mainTeacherId
  );
  const mainTeacherStr = `${mainTeacherData.lastName} ${mainTeacherData.firstName} ${mainTeacherData.middleName}`;

  const otherTeachersStr = teachers
    .filter(teacher => teacher.teacherId !== mainTeacherId)
    .map(
      teacher =>
        `${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`
    )
    .join(', ');

  return {
    pageOrientation: 'landscape',
    content: [
      {
        text: 'Державний вищий навчальний заклад',
        style: 'header_title_1',
      },
      {
        text: 'НАЦІОНАЛЬНИЙ ЛІСОТЕХНІЧНИЙ УНІВЕРСИТЕТ УКРАЇНИ',
        style: 'header_title_2',
      },
      {
        text: `${institute.instituteName}`,
        style: 'header_body_institute',
      },
      {
        text: `Спеціальність: ${specialty.specialtyName}`,
        style: 'header_body_speciality',
      },
      {
        text: `Група ${report.group.groupName} - ${(function() {
          const educationYear =
            new Date().getUTCFullYear() - report.group.groupEducationStartYear;
          if (report.group.groupEducationType === 'bachelor') {
            return educationYear > 4
              ? 4
              : educationYear < 1
              ? 1
              : educationYear;
          }
          if (report.group.groupEducationType === 'master') {
            return educationYear > 2
              ? 2
              : educationYear < 1
              ? 1
              : educationYear;
          }
        })()}${report.group.groupNumber}    Семестр ${educationSemester}    ${
          department.departmentName
        }`,
        style: 'header_body_speciality',
      },
      {
        text: `${educationStartYear}-${educationEndYear} навчальний рік`,
        style: 'header_body_study_years',
      },
      {
        text: 'ВІДОМІСТЬ ОБЛІКУ УСПІШНОСТІ № _________',
        style: 'header_body_report_number',
      },
      {
        text: `(для дисциплін, що закінчуються ${
          reportType === 'zalik' ? 'заліком' : 'екзаменом'
        })`,
        style: 'header_body_report_type',
      },
      {
        text: `${reportDate.split('-')[2]} ${(function() {
          switch (reportDate.split('-')[1]) {
            case '01':
              return 'cіченя';
            case '02':
              return 'лютого';
            case '03':
              return 'березня';
            case '04':
              return 'квітня';
            case '05':
              return 'травня';
            case '06':
              return 'червня';
            case '07':
              return 'липня';
            case '08':
              return 'серпня';
            case '09':
              return 'вересня';
            case '10':
              return 'жовтня';
            case '11':
              return 'листопада';
            case '12':
              return 'грудня';

            default:
          }
        })()} ${reportDate.split('-')[0]} року`,
        style: 'header_body_report_date',
      },
      {
        text: `Дисципліна ${subjectName}`,
        style: 'main_body_info',
      },
      {
        text: `Лектор: ${mainTeacherStr}`,
        style: 'main_body_info',
      },
      {
        text: `Викладачі, які ведуть лабораторні, практичні, семінарські тощо заняття: ${otherTeachersStr}`,
        style: 'main_body_info',
      },
      {
        text: `Дати проведення контрольних заходів ${
          reportType === 'zalik' ? 'КЗ' : 'МК'
        }1: ${kzOrMk1Date}; ${
          reportType === 'zalik' ? 'КЗ' : 'МК'
        }2: ${kzOrMk2Date}; ${
          reportType === 'zalik' ? 'ЗК' : 'ЕК'
        }: ${zkOrEkDate}`,
        style: 'main_body_info',
      },
      // {
      //   text: `02103580`,
      //   style: 'main_body_info',
      // },
      {
        table: {
          body: [
            [
              { text: '№', rowSpan: 3, alignment: 'center', margin: [2, 30] },
              {
                text: `Прізвище ім'я по-батькові`,
                rowSpan: 3,
                alignment: 'center',
                margin: [5, 30],
              },
              {
                text: 'Модульні оцінки',
                alignment: 'center',
                colSpan: 8,
                fontSize: 10,
              },
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              {
                text:
                  reportType === 'zalik'
                    ? 'Залікова оцінка'
                    : 'Екзаменаційна оцінка',
                alignment: 'center',
                colSpan: 3,
                fontSize: 10,
              },
              '',
              '',
              {
                text: `Семестрова оцінка СО=СМО\nабо СО=ЗО`,
                alignment: 'center',
                colSpan: 3,
                fontSize: 10,
              },
              '',
              '',
              {
                text: `Підпис\nвикладача`,
                rowSpan: 3,
                alignment: 'center',
                margin: [10, 20],
              },
            ],
            [
              '',
              '',
              { text: 'ЗМ1', colSpan: 3, alignment: 'center', fontSize: 10 },
              '',
              '',
              { text: 'ЗМ2', colSpan: 3, alignment: 'center', fontSize: 10 },
              '',
              '',
              {
                text: `Додат-\nкові\nбали(*)`,
                rowSpan: 2,
                fontSize: 6,
                alignment: 'center',
                margin: [2, 10],
              },
              {
                text: `СМО=\nМО1+\nМО2`,
                rowSpan: 2,
                fontSize: 6,
                alignment: 'center',
                margin: [2, 10],
              },
              {
                text: `ПК=\nПК1+\nПК2`,
                rowSpan: 2,
                fontSize: 6,
                alignment: 'center',
                margin: [2, 10],
              },
              {
                text: reportType === 'zalik' ? 'ЗК' : 'ЕК',
                rowSpan: 2,
                fontSize: 8,
                alignment: 'center',
                margin: [2, 15],
              },
              {
                text: reportType === 'zalik' ? `ЗО=\nПК+\nЗК` : `ЕО=\nПК+\nЕК`,
                rowSpan: 2,
                fontSize: 7,
                alignment: 'center',
                margin: [2, 10],
              },
              {
                text: `За 100\nбальною\nшкалою`,
                rowSpan: 2,
                fontSize: 7,
                alignment: 'center',
                margin: [2, 10],
              },
              {
                text: `За національною\nшкалою (прописом)`,
                rowSpan: 2,
                fontSize: 7,
                alignment: 'center',
                margin: [2, 10],
              },
              {
                text: `За\nШкалою\nЄКТС`,
                rowSpan: 2,
                fontSize: 7,
                alignment: 'center',
                margin: [2, 10],
              },
              '',
            ],
            [
              '',
              '',
              { text: 'ПК1', fontSize: 8, alignment: 'center', margin: [2, 7] },
              { text: 'МК1', fontSize: 8, alignment: 'center', margin: [2, 7] },
              {
                text: `МО1=\nПК1+\nМК1`,
                fontSize: 6,
                alignment: 'center',
                margin: [2, 3],
              },
              { text: 'ПК2', fontSize: 8, alignment: 'center', margin: [2, 7] },
              { text: 'МК2', fontSize: 8, alignment: 'center', margin: [2, 7] },
              {
                text: `МО2=\nПК2+\nМК2`,
                fontSize: 6,
                alignment: 'center',
                margin: [2, 3],
              },
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ],
            ...reportData.map((studentReportRecord, idx) => {
              const pk1 = studentReportRecord.pk1
                ? Number(studentReportRecord.pk1)
                : 0;
              const mk1 = studentReportRecord.mk1
                ? Number(studentReportRecord.mk1)
                : 0;

              const mo1Sum =
                Number(
                  studentReportRecord.pk1 ? Number(studentReportRecord.pk1) : 0
                ) +
                Number(
                  studentReportRecord.mk1 ? Number(studentReportRecord.mk1) : 0
                );
              const mo1 = mo1Sum === 0 ? 0 : mo1Sum;

              const pk2 = studentReportRecord.pk2
                ? Number(studentReportRecord.pk2)
                : 0;
              const mk2 = studentReportRecord.mk2
                ? Number(studentReportRecord.mk2)
                : 0;

              const mo2Sum =
                Number(
                  studentReportRecord.pk2 ? Number(studentReportRecord.pk2) : 0
                ) +
                Number(
                  studentReportRecord.mk2 ? Number(studentReportRecord.mk2) : 0
                );
              const mo2 = mo2Sum === 0 ? 0 : mo2Sum;

              const extraPoints = studentReportRecord.extraPoints
                ? Number(studentReportRecord.extraPoints)
                : null;

              const smoSum =
                Number(mo1 ? mo1 : 0) +
                Number(mo2 ? mo2 : 0) +
                Number(extraPoints ? extraPoints : 0);
              const smo = smoSum === 0 ? 0 : smoSum;

              const pkSum =
                Number(
                  studentReportRecord.pk1 ? Number(studentReportRecord.pk1) : 0
                ) +
                Number(
                  studentReportRecord.pk2 ? Number(studentReportRecord.pk2) : 0
                );

              const zkOrEk = studentReportRecord.zkOrEk
                ? Number(studentReportRecord.zkOrEk)
                : null;

              const pk = zkOrEk ? (pkSum === 0 ? null : pkSum) : null;

              const zoOrEoSum =
                Number(zkOrEk ? zkOrEk : 0) + Number(pk ? pk : 0);
              const zoOrEo = zoOrEoSum === 0 ? null : zoOrEoSum;

              const semesterMark = zkOrEk ? zoOrEo : smo;

              let nationalMark;

              if (reportType === 'zalik') {
                nationalMark = semesterMark
                  ? semesterMark >= 50
                    ? 'зараховано'
                    : semesterMark === 0
                    ? `не з'явився`
                    : 'не зараховано'
                  : 'не зараховано';
              }
              if (reportType === 'ekzamen') {
                nationalMark = semesterMark
                  ? semesterMark >= 90
                    ? 'відмінно'
                    : semesterMark >= 83
                    ? 'добре'
                    : semesterMark >= 75
                    ? 'добре'
                    : semesterMark >= 68
                    ? 'задовільно'
                    : semesterMark >= 50
                    ? 'задовільно'
                    : semesterMark >= 26
                    ? 'незадовільно'
                    : semesterMark >= 1
                    ? 'незадовільно'
                    : `не з'явився`
                  : `не з'явився`;
              }

              const ektsMark = semesterMark
                ? semesterMark >= 90
                  ? 'A'
                  : semesterMark >= 83
                  ? 'B'
                  : semesterMark >= 75
                  ? 'C'
                  : semesterMark >= 68
                  ? 'D'
                  : semesterMark >= 50
                  ? 'E'
                  : semesterMark >= 26
                  ? 'FX'
                  : 'F'
                : 'F';

              return [
                { text: idx + 1, alignment: 'center' },
                `${studentReportRecord.student.lastName.trim()} ${studentReportRecord.student.firstName.trim()} ${studentReportRecord.student.middleName.trim()}`,
                { text: pk1, alignment: 'center' },
                { text: mk1, alignment: 'center' },
                { text: mo1, alignment: 'center' },
                { text: pk2, alignment: 'center' },
                { text: mk2, alignment: 'center' },
                { text: mo2, alignment: 'center' },
                { text: extraPoints, alignment: 'center' },
                { text: smo, alignment: 'center' },
                { text: pk, alignment: 'center' },
                { text: zkOrEk, alignment: 'center' },
                { text: zoOrEo, alignment: 'center' },
                { text: semesterMark, alignment: 'center' },
                { text: nationalMark, alignment: 'center' },
                { text: ektsMark, alignment: 'center' },
                '',
              ];
            }),
            [
              '',
              { text: 'Максимальна кількість', alignment: 'right' },
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ],
          ],
        },
        style: 'main_table',
      },
      {
        text: `Умовні позначення:МК1,МК2 - модульний контроль; ПК1,ПК2,ПК - поточний контроль (max 40 балів); МО1,МО2 - модульні оцінки; СМО - сумарна\nмодульна оцінка; ЗК - заліковий контроль (max 60 балів); ЗО - залікова оцінка; СО - семестрова оцінка.\n* - додаткові бали за наукові й навчальні досягнення згідно з п.3.5 (до 10 балів, що входять у СМО).`,
        style: 'main_table_footer',
      },
      {
        text: 'Підсумки:',
        style: 'summary_table_title',
      },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              body: [
                [
                  {
                    text: 'Всього оцінок',
                    alignment: 'center',
                    margin: [0, 0],
                  },
                  {
                    text: 'За 100 бальною\nшкалою',
                    alignment: 'center',
                    margin: [0, 0],
                  },
                  {
                    text: 'Оцінка за\nшкалою ЄКТС',
                    alignment: 'center',
                    margin: [0, 0],
                  },
                  {
                    text: 'Оцінка за\nнаціональною\nшкалою',
                    alignment: 'center',
                    margin: [0, 0],
                  },
                ],
                [
                  '',
                  '90-100',
                  'A',
                  reportType === 'zalik' ? 'зараховано' : 'відмінно',
                ],
                [
                  '',
                  '83-89',
                  'B',
                  reportType === 'zalik' ? 'зараховано' : 'добре',
                ],
                [
                  '',
                  '75-82',
                  'C',
                  reportType === 'zalik' ? 'зараховано' : 'добре',
                ],
                [
                  '',
                  '68-74',
                  'D',
                  reportType === 'zalik' ? 'зараховано' : 'задовільно',
                ],
                [
                  '',
                  '50-67',
                  'E',
                  reportType === 'zalik' ? 'зараховано' : 'задовільно',
                ],
                [
                  '',
                  '26-49',
                  'FX',
                  reportType === 'zalik' ? 'не зараховано' : 'незадовільно',
                ],
                [
                  '',
                  '0-25',
                  'F',
                  reportType === 'zalik' ? 'не зараховано' : 'незадовільно',
                ],
                ['', '', '', `не з'явився`],
              ],
            },
            style: 'summary_table',
          },
          { width: '*', text: '' },
        ],
      },
      {
        text: `Екзаменатор (викладач)________________________________	_________________________
Завідувач кафедри _____________________________________	_________________________
Директор навчально-наукового інституту
`,
        style: 'footer_info',
      },
    ],
    styles: {
      header_title_1: {
        alignment: 'center',
        margin: [0, -15, 0, 0],
      },
      header_title_2: {
        alignment: 'center',
        margin: [0, 0, 0, 0],
      },
      header_body_institute: {
        alignment: 'left',
        margin: [-15, 10, 0, 0],
      },
      header_body_speciality: {
        alignment: 'left',
        margin: [-15, 0, 0, 0],
      },
      header_body_study_years: {
        alignment: 'center',
        margin: [0, 10, 0, 0],
      },
      header_body_report_number: {
        alignment: 'center',
        margin: [0, 0, 0, 0],
      },
      header_body_report_type: {
        alignment: 'center',
        margin: [0, 0, 0, 0],
      },
      header_body_report_date: {
        alignment: 'center',
        margin: [0, 0, 0, 0],
      },
      main_body_info: {
        alignment: 'left',
        margin: [-15, 0, 0, 0],
      },
      main_table: {
        alignment: 'left',
        margin: [-20, 0, 0, 0],
      },
      main_table_footer: {
        alignment: 'center',
        fontSize: 10,
        margin: [0, 15, 0, 0],
      },
      summary_table_title: {
        alignment: 'center',
        fontSize: 8,
        margin: [0, 15, 0, 0],
      },
      summary_table: {
        alignment: 'center',
        fontSize: 8,
        margin: [0, 3, 0, 0],
      },
      footer_info: {
        alignment: 'left',
        fontSize: 12,
        margin: [0, 5, 0, 0],
      },
    },
    defaultStyle: {
      fontSize: 12,
    },
  };
};
