import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import HomePage from './Home';
import NotFound from './not-found.page';
import PrivateRoute from '../features/authorization/private-route.hoc';
import UnauthenticatedOnlyRoute from '../features/authorization/unauthenticated-only-route.hoc';
import ErrorBoundary from './erorr-boundary';

import withAuthentication from '../features/authorization/with-authentication.hoc';
import SignInPage from '../features/authorization/sign-in.page';
import GroupsPage from '../features/groups/groups-page';
import GroupPage from '../features/groups/group-page';
import StudentsPage from '../features/students/students-page';
import TeachersPage from '../features/teachers/teachers-page';
import SubjectsReportsPage from '../features/subjects-reports/subjects-reports-page';
import SubjectReportPage from '../features/subjects-reports/subject-report-page';

class App extends Component {
  render() {
    return (
      <div className="bg-light text-dark">
        <ErrorBoundary>
          <Router>
            <React.Fragment>
              {/* <Redirect from='/' to='/main' exact /> */}
              <Switch>
                <UnauthenticatedOnlyRoute
                  exact
                  path="/login"
                  component={SignInPage}
                />
                <Redirect from="/" to="/login" exact />
                <PrivateRoute exact path="/main" component={HomePage} />
                <PrivateRoute exact path="/groups" component={GroupsPage} />
                <PrivateRoute
                  exact
                  path="/groups/:groupId"
                  component={GroupPage}
                />
                <PrivateRoute exact path="/students" component={StudentsPage} />
                <PrivateRoute exact path="/teachers" component={TeachersPage} />
                <PrivateRoute
                  exact
                  path="/my-reports"
                  component={SubjectsReportsPage}
                />
                <PrivateRoute
                  exact
                  path="/subjects-reports/:reportId"
                  component={SubjectReportPage}
                />
                <Route component={NotFound} />
              </Switch>
            </React.Fragment>
          </Router>
        </ErrorBoundary>
      </div>
    );
  }
}

export default withAuthentication(App);
