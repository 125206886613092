import React, { Component } from 'react';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import Loader from '../../components/Loader';

const initial_state = {
  teacherFirstName: '',
  teacherLastName: '',
  teacherMiddleName: '',
  teacherId: '',
  teacherPassword: '',
  teacherDepartmentId: '',
  departments: [],
  isAdmin: false,
  error: null,
  isLoading: false,
};

class AddTeacherModalForm extends Component {
  constructor(props) {
    super(props);
    this.addTeachermodalRef = React.createRef();
    this.state = { ...initial_state };
  }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/departments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('departments', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  onSubmit = (event, isInvalid) => {
    const addTeachermodalNode = this.addTeachermodalRef.current;
    const hiddenAddTeachermodalNode = document.querySelector('.modal-backdrop');

    if (isInvalid) {
      this.setState({
        error: `Будь-ласка заповніть всі поля!`,
      });
    } else {
      this.setState(stateSetter('isLoading', true));

      const {
        teacherFirstName,
        teacherLastName,
        teacherMiddleName,
        teacherId,
        teacherPassword,
        teacherDepartmentId,
      } = this.state;

      fetch(`${constants.BE_BASE_URL}/teachers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        },
        body: JSON.stringify({
          firstName: teacherFirstName,
          lastName: teacherLastName,
          middleName: teacherMiddleName,
          teacherId,
          password: teacherPassword,
          departmentId: teacherDepartmentId,
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            addTeachermodalNode.removeAttribute('aria-modal');
            addTeachermodalNode.setAttribute('aria-hidden', 'true');
            addTeachermodalNode.classList.remove('show');
            addTeachermodalNode.style.display = 'none';
            hiddenAddTeachermodalNode.classList.remove('show');
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(stateSetter('error', data.message));
          }

          if (String(res.status).startsWith(5)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
    event.preventDefault();
  };

  handleChange(event, property) {
    return this.setState(stateSetter(property, event.target.value));
  }

  render() {
    const {
      teacherFirstName,
      teacherLastName,
      teacherMiddleName,
      teacherId,
      teacherPassword,
      teacherDepartmentId,
      departments,
      error,
      isLoading,
    } = this.state;

    const isInvalid =
      teacherFirstName === '' ||
      teacherLastName === '' ||
      teacherMiddleName === '' ||
      teacherId === '' ||
      teacherPassword === '' ||
      teacherDepartmentId === '';

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <div className="container ">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addTeacherModal"
            >
              Додати вчителя
            </button>
            <div
              className="modal fade"
              id="addTeacherModal"
              ref={this.addTeachermodalRef}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Заповніть форму даними про вчителя
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      className="justify-content-center"
                      onSubmit={e => this.onSubmit(e, isInvalid)}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={teacherLastName}
                          placeholder="Прізвище"
                          onChange={event =>
                            this.handleChange(event, 'teacherLastName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={teacherFirstName}
                          placeholder="Ім'я"
                          onChange={event =>
                            this.handleChange(event, 'teacherFirstName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={teacherMiddleName}
                          placeholder="По-батькові"
                          onChange={event =>
                            this.handleChange(event, 'teacherMiddleName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={teacherId}
                          placeholder="Ітендифікатор вчителя"
                          onChange={event =>
                            this.handleChange(event, 'teacherId')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={teacherPassword}
                          placeholder="Пароль"
                          onChange={event =>
                            this.handleChange(event, 'teacherPassword')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={teacherDepartmentId}
                          onChange={event =>
                            this.handleChange(event, 'teacherDepartmentId')
                          }
                        >
                          <option value="" disabled>
                            Кафедра
                          </option>
                          {departments.map(department => (
                            <option
                              key={department.departmentId}
                              value={department.departmentId}
                            >
                              {department.departmentAbbreviationName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Додати
                      </button>
                    </form>
                  </div>

                  {/* <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Закрити</button>
                    </div> */}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddTeacherModalForm;
