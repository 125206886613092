import React from 'react';

import AuthUserContext from './auth-user.context';
import AuthService from './auth-service';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.Auth = new AuthService();

      this.state = {
        token: this.Auth.getToken(),
        login: this.Auth.getLogin(),
      };

      this.authorize = this.authorize.bind(this);
      this.logout = this.logout.bind(this);
    }

    authorize(token, login) {
      this.Auth.setToken(token);
      this.Auth.setLogin(login);

      this.setState({
        token: token,
        login: login,
      });
    }

    logout() {
      this.Auth.logout();

      this.setState({
        token: null,
      });
    }

    render() {
      const auth = {
        token: this.state.token,
        login: this.state.login,
        authorize: this.authorize,
        logout: this.logout,
      };

      return (
        <AuthUserContext.Provider value={auth}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
