import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavTopBar from '../nav-top-bar/nav-top-bar';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import AddStudentModalForm from './add-student-modal-form';
import Loader from '../../components/Loader';

const initial_state = {
  students: [],
  isLoading: false,
  isAdmin: false,
  error: null,
};

class StudentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial_state };
  }

  // handleChange(event, property) {
  //   return this.setState(stateSetter(property, event.target.value));
  // }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/students`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('students', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    this.setState(
      stateSetter(
        'isAdmin',
        String(this.props.auth.login).toLowerCase() === 'admin'
      )
    );
  }

  handleDeleteStudentDataButton(e) {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/students/${e.target.value}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        if (String(res.status).startsWith(2)) {
          window.location.reload(false);
        }

        if (String(res.status).startsWith(4)) {
          const data = await res.json();

          this.setState(stateSetter('error', data.message));
          this.setState(stateSetter('isLoading', false));
        }

        if (String(res.status).startsWith(5)) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  render() {
    const { students, isLoading, isAdmin, error } = this.state;

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <header>
            <NavTopBar auth={this.props.auth} />
          </header>
          <main className="vh-100">
            <div className="container pt-5 pb-3">
              <h3 className="pt-5 py-4 border-bottom">Усі студенти</h3>
            </div>
            {isAdmin ? (
              <AddStudentModalForm token={this.props.auth.token} />
            ) : (
              <div></div>
            )}
            <div className="container py-4">
              <div>
                {error && <p className="text-danger text-center">{error}</p>}
              </div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Прізвище</th>
                    <th scope="col">Ім'я</th>
                    <th scope="col">По-батькові</th>
                    <th scope="col">Номер студентського квитка</th>
                    <th scope="col">Номер залікової книжки</th>
                    <th scope="col">Група</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, i) => (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>{student.lastName}</td>
                      <td>{student.firstName}</td>
                      <td>{student.middleName}</td>
                      <td>{student.studentId}</td>
                      <td>{student.gradeBookId}</td>
                      <td>
                        {student.group
                          ? `${student.group.groupName} - ${(function() {
                              const educationYear =
                                new Date().getUTCFullYear() -
                                student.group.groupEducationStartYear;
                              if (
                                student.group.groupEducationType === 'bachelor'
                              ) {
                                return educationYear > 4
                                  ? 4
                                  : educationYear < 1
                                  ? 1
                                  : educationYear;
                              }
                              if (
                                student.group.groupEducationType === 'master'
                              ) {
                                return educationYear > 2
                                  ? 2
                                  : educationYear < 1
                                  ? 1
                                  : educationYear;
                              }
                            })()}${student.group.groupNumber}`
                          : ''}
                      </td>
                      {isAdmin ? (
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger m-0"
                            value={student.studentId}
                            onClick={e => this.handleDeleteStudentDataButton(e)}
                          >
                            Видалити
                          </button>
                        </td>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(StudentsPage);
