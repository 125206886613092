import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({ location }) => (
  <div className="vw-100 vh-100 d-flex align-items-center justify-content-center flex-column text-center">
    <h3>
      Такий шлях не знайдено: <code>{location.pathname}</code>
    </h3>
    <p>
      Поверніться на <Link to="/main">головну</Link> сторінку
    </p>
  </div>
);

export default NotFound;
