import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthUserContext from './auth-user.context';

const PrivateRoute = props => {
  const { component: Component, ...rest } = props;

  return (
    <AuthUserContext.Consumer>
      {auth => {
        return auth.token ? (
          <Route {...rest} render={props => <Component auth={auth} />} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    </AuthUserContext.Consumer>
  );
};

export default PrivateRoute;
