import React, { Component } from 'react';
import { stateSetter } from '../../common/utils';
import { withRouter } from 'react-router-dom';
import constants from '../../constants';
import Loader from '../../components/Loader';
import Select from 'react-select';

const initial_state = {
  reportType: '',
  instituteId: '',
  specialityId: '',
  departmentId: '',
  subjectName: '',
  groupId: '',
  educationStartYear: '',
  educationEndYear: '',
  educationSemester: '',
  mainTeacherId: '',
  reportDate: '',
  kzOrMk1Date: '',
  kzOrMk2Date: '',
  zkOrEkDate: '',
  allAssignedTeachersIds: [],
  teachers: [],
  groups: [],
  group: null,
  institutes: [],
  departments: [],
  specialities: [],
  error: null,
  isLoading: false,
};

class AddSubjectReportModalForm extends Component {
  constructor(props) {
    super(props);

    this.addSubjectReportModalRef = React.createRef();

    this.state = { ...initial_state };

    const currYear = new Date().getUTCFullYear();
    this.availableYears = Array(currYear - (currYear - 20))
      .fill('')
      .map((v, idx) => currYear - idx);

    this.handleMultipleSelectChange = this.handleMultipleSelectChange.bind(
      this
    );
  }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/teachers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('teachers', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/groups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('groups', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/institutes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('institutes', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/departments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('departments', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/specialities`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('specialities', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  onSubmit = (event, isInvalid) => {
    const addSubjectReportmodalNode = this.addSubjectReportModalRef.current;
    const hiddenAddSubjectReportmodalNode = document.querySelector(
      '.modal-backdrop'
    );

    if (isInvalid) {
      this.setState({
        error: `Будь-ласка заповніть всі поля!`,
      });
    } else {
      this.setState(stateSetter('isLoading', true));

      const {
        reportType,
        instituteId,
        specialityId,
        departmentId,
        subjectName,
        groupId,
        educationStartYear,
        // educationEndYear,
        reportDate,
        kzOrMk1Date,
        kzOrMk2Date,
        zkOrEkDate,
        educationSemester,
        mainTeacherId,
        allAssignedTeachersIds,
      } = this.state;

      console.log(specialityId);

      fetch(`${constants.BE_BASE_URL}/subjects-reports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        },
        body: JSON.stringify({
          reportType,
          instituteId,
          specialityId,
          departmentId,
          subjectName,
          groupId,
          reportDate,
          kzOrMk1Date,
          kzOrMk2Date,
          zkOrEkDate,
          educationStartYear: Number(educationStartYear),
          educationEndYear: Number(educationStartYear) + 1,
          educationSemester: Number(educationSemester),
          mainTeacherId,
          allAssignedTeachersIds: [...allAssignedTeachersIds, mainTeacherId],
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            addSubjectReportmodalNode.removeAttribute('aria-modal');
            addSubjectReportmodalNode.setAttribute('aria-hidden', 'true');
            addSubjectReportmodalNode.classList.remove('show');
            addSubjectReportmodalNode.style.display = 'none';
            hiddenAddSubjectReportmodalNode.classList.remove('show');
            this.setState({ ...initial_state });
            this.props.history.push(`subjects-reports/${data.reportId}`);
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            // this.setState({ ...initial_state });
            this.setState(stateSetter('error', data.message));
            this.setState(stateSetter('isLoading', false));
          }

          if (String(res.status).startsWith(5)) {
            // this.setState({ ...initial_state });
            this.setState(
              stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
            );
            this.setState(stateSetter('isLoading', false));
          }
        })
        .catch(err => {
          // this.setState({ ...initial_state });
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
    event.preventDefault();
  };

  handleChange(event, property) {
    this.setState(stateSetter(property, event.target.value));

    if (property === 'groupId') {
      this.setState(stateSetter('isLoading', true));

      fetch(`${constants.BE_BASE_URL}/groups/${event.target.value}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        },
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            this.setState(stateSetter('group', data));
            this.setState(stateSetter('isLoading', false));
          }

          if (
            String(res.status).startsWith(5) ||
            String(res.status).startsWith(4)
          ) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }

    if (property === 'instituteId') {
      this.setState(
        stateSetter(
          'specialities',
          this.state.specialities.filter(
            speciality =>
              speciality.institute.instituteId === event.target.value
          )
        )
      );
    }

    if (property === 'departmentId') {
      this.setState(
        stateSetter(
          'teachers',
          this.state.teachers.filter(
            teacher =>
              teacher.department &&
              teacher.department.departmentId === event.target.value
          )
        )
      );
    }
  }

  handleMultipleSelectChange(values) {
    console.log(values);
    this.setState(
      stateSetter(
        'allAssignedTeachersIds',
        values ? values.map(value => value.value) : []
      )
    );
  }

  render() {
    const {
      reportType,
      instituteId,
      specialityId,
      departmentId,
      subjectName,
      groupId,
      group,
      educationStartYear,
      // educationEndYear,
      educationSemester,
      mainTeacherId,
      teachers,
      groups,
      reportDate,
      kzOrMk1Date,
      kzOrMk2Date,
      zkOrEkDate,
      allAssignedTeachersIds,

      institutes,
      departments,
      specialities,

      error,
      isLoading,
    } = this.state;

    const isInvalid =
      reportType === '' ||
      instituteId === '' ||
      specialityId === '' ||
      departmentId === '' ||
      subjectName === '' ||
      groupId === '' ||
      educationStartYear === '' ||
      educationSemester === '' ||
      reportDate === '' ||
      kzOrMk1Date === '' ||
      kzOrMk2Date === '' ||
      zkOrEkDate === '' ||
      mainTeacherId === '';

    const teachersMultiSelectOptions = teachers
      .filter(
        teacher => !allAssignedTeachersIds.find(id => id === teacher.teacherId)
      )
      .map(teacher => ({
        value: teacher.teacherId,
        label: `${teacher.lastName} ${teacher.firstName.split('')[0]}. ${
          teacher.middleName.split('')[0]
        }.`,
      }));

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <div className="container ">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addSubjectReportModal"
            >
              Додати відомість
            </button>
            <div
              className="modal fade"
              id="addSubjectReportModal"
              ref={this.addSubjectReportModalRef}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Заповніть форму даними про відомість
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      className="justify-content-center"
                      onSubmit={e => this.onSubmit(e, isInvalid)}
                    >
                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={reportType}
                          placeholder="Тип відомості (залік, екзамен)"
                          onChange={event =>
                            this.handleChange(event, 'reportType')
                          }
                        >
                          <option value="" disabled>
                            Тип відомості (залік, екзамен)
                          </option>
                          <option value="zalik">Залік</option>
                          <option value="ekzamen">Екзамен</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          value={instituteId}
                          onChange={event =>
                            this.handleChange(event, 'instituteId')
                          }
                        >
                          <option value="" disabled>
                            Інститут
                          </option>
                          {institutes.map(institute => (
                            <option
                              key={institute.instituteId}
                              value={institute.instituteId}
                            >
                              {institute.instituteAbbreviationName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          value={specialityId}
                          disabled={instituteId ? '' : 'disabled'}
                          onChange={event =>
                            this.handleChange(event, 'specialityId')
                          }
                        >
                          <option value="" disabled>
                            Спеціальність
                          </option>
                          {specialities.map(speciality => (
                            <option
                              key={speciality.specialtyId}
                              value={speciality.specialtyId}
                            >
                              {speciality.specialtyName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          value={departmentId}
                          onChange={event =>
                            this.handleChange(event, 'departmentId')
                          }
                        >
                          <option value="" disabled>
                            Кафедра
                          </option>
                          {departments.map(department => (
                            <option
                              key={department.departmentId}
                              value={department.departmentId}
                            >
                              {department.departmentAbbreviationName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={subjectName}
                          placeholder="Назва предмету"
                          onChange={event =>
                            this.handleChange(event, 'subjectName')
                          }
                        />
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={groupId}
                          onChange={event =>
                            this.handleChange(event, 'groupId')
                          }
                        >
                          <option value="" disabled>
                            Група
                          </option>
                          {groups.map(group => (
                            <option key={group.groupId} value={group.groupId}>
                              {group
                                ? `${group.groupName} - ${(function() {
                                    const educationYear =
                                      new Date().getUTCFullYear() -
                                      group.groupEducationStartYear;
                                    if (
                                      group.groupEducationType === 'bachelor'
                                    ) {
                                      return educationYear > 4
                                        ? 4
                                        : educationYear < 1
                                        ? 1
                                        : educationYear;
                                    }
                                    if (group.groupEducationType === 'master') {
                                      return educationYear > 2
                                        ? 2
                                        : educationYear < 1
                                        ? 1
                                        : educationYear;
                                    }
                                  })()}${group.groupNumber}`
                                : ''}{' '}
                              {group.groupEducationType === 'bachelor'
                                ? `${
                                    group.groupEducationStartYear
                                  } - ${group.groupEducationStartYear + 4}`
                                : `${
                                    group.groupEducationStartYear
                                  } - ${group.groupEducationStartYear + 2}`}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={educationStartYear}
                          disabled={group ? '' : 'diabled'}
                          onChange={event =>
                            this.handleChange(event, 'educationStartYear')
                          }
                        >
                          <option value="" disabled>
                            Рік Навчання
                          </option>

                          {group ? (
                            (function() {
                              const startYear = Number(
                                group.groupEducationStartYear
                              );
                              const availableYears = Array(
                                group.groupEducationType === 'bachelor' ? 4 : 2
                              )
                                .fill('')
                                .map((v, idx) => startYear + idx);

                              return availableYears.map(year => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ));
                            })()
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={educationSemester}
                          onChange={event =>
                            this.handleChange(event, 'educationSemester')
                          }
                        >
                          <option value="" disabled>
                            Навчальний семестр
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={mainTeacherId}
                          disabled={departmentId ? '' : 'diabled'}
                          onChange={event =>
                            this.handleChange(event, 'mainTeacherId')
                          }
                        >
                          <option value="" disabled>
                            Лектор
                          </option>
                          {teachers.map(teacher => (
                            <option
                              key={teacher.teacherId}
                              value={teacher.teacherId}
                            >
                              {`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <Select
                          isMulti
                          placeholder="Інші вчителі(лабораторні, практичні)"
                          isDisabled={departmentId ? false : true}
                          options={teachersMultiSelectOptions}
                          onChange={this.handleMultipleSelectChange}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={() =>
                            new Date().toLocaleDateString('en-US')
                          }
                          onChange={event =>
                            this.handleChange(event, 'reportDate')
                          }
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={kzOrMk1Date}
                          placeholder="Дата першого залікового контролю"
                          onChange={event =>
                            this.handleChange(event, 'kzOrMk1Date')
                          }
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={kzOrMk2Date}
                          placeholder="Дата другого залікового контролю"
                          onChange={event =>
                            this.handleChange(event, 'kzOrMk2Date')
                          }
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={zkOrEkDate}
                          placeholder="Дата заліку або екзамену"
                          onChange={event =>
                            this.handleChange(event, 'zkOrEkDate')
                          }
                        />
                      </div>

                      <div>
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Додати
                      </button>
                    </form>
                  </div>

                  {/* <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Закрити</button>
                    </div> */}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddSubjectReportModalForm);
