import React, { Component } from 'react';

class Loader extends Component {
  render() {
    return (
      <div
        id="spinnerWrap"
        className="d-flex align-items-center justify-content-center text-center"
      >
        <div
          className="spinner-border text-secondary"
          style={{ width: '4rem', height: '4rem' }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default Loader;
