import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import Loader from '../../components/Loader';

const initial_state = {
  groupName: '',
  groupNumber: '',
  groupEducationType: '',
  groupEducationStartYear: '',
  error: null,
  isLoading: false,
};

class AddGroupModalForm extends Component {
  constructor(props) {
    super(props);
    this.addGroupmodalRef = React.createRef();
    this.state = { ...initial_state };

    const currYear = new Date().getUTCFullYear();
    this.availableYears = Array(currYear - (currYear - 20))
      .fill('')
      .map((v, idx) => currYear - idx);
  }

  onSubmit = (event, isInvalid) => {
    const addStudentmodalNode = this.addGroupmodalRef.current;
    const hiddenAddStudentmodalNode = document.querySelector('.modal-backdrop');

    if (isInvalid) {
      this.setState({
        error: `Будь-ласка заповніть всі поля!`,
      });
    } else {
      this.setState(stateSetter('isLoading', true));

      const {
        groupName,
        groupNumber,
        groupEducationType,
        groupEducationStartYear,
      } = this.state;

      fetch(`${constants.BE_BASE_URL}/groups`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        },
        body: JSON.stringify({
          groupName,
          groupNumber: Number(groupNumber),
          groupEducationType,
          groupEducationStartYear: Number(groupEducationStartYear),
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            console.log(data);
            addStudentmodalNode.removeAttribute('aria-modal');
            addStudentmodalNode.setAttribute('aria-hidden', 'true');
            addStudentmodalNode.classList.remove('show');
            addStudentmodalNode.style.display = 'none';
            hiddenAddStudentmodalNode.classList.remove('show');
            this.setState({ ...initial_state });
            this.props.history.push(`groups/${data.groupId}`);
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(stateSetter('error', data.message));
          }

          if (String(res.status).startsWith(5)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
    event.preventDefault();
  };

  handleChange(event, property) {
    return this.setState(stateSetter(property, event.target.value));
  }

  render() {
    const {
      groupName,
      groupNumber,
      groupEducationType,
      groupEducationStartYear,
      error,
      isLoading,
    } = this.state;

    const isInvalid =
      groupName === '' ||
      groupNumber === '' ||
      groupEducationType === '' ||
      groupEducationStartYear === '';

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <div className="container mt-4">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addStudentModal"
            >
              Додати групу
            </button>
            <div
              className="modal fade"
              id="addStudentModal"
              ref={this.addGroupmodalRef}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Заповніть форму даними про групу
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      className="justify-content-center"
                      onSubmit={e => this.onSubmit(e, isInvalid)}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={groupName}
                          placeholder="Назва групи"
                          onChange={event =>
                            this.handleChange(event, 'groupName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={groupNumber}
                          onChange={event =>
                            this.handleChange(event, 'groupNumber')
                          }
                        >
                          <option value={groupEducationType} disabled>
                            Номер групи
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="7">8</option>
                          <option value="7">9</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={groupEducationType}
                          onChange={event =>
                            this.handleChange(event, 'groupEducationType')
                          }
                        >
                          <option value={groupEducationType} disabled>
                            Ступінь освіти (бакалавр, магістр)
                          </option>
                          <option value="bachelor">Бакалавр</option>
                          <option value="master">Магістр</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={groupEducationStartYear}
                          onChange={event =>
                            this.handleChange(event, 'groupEducationStartYear')
                          }
                        >
                          <option value="" disabled>
                            Рік початку Навчання
                          </option>
                          {this.availableYears.map(year => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Додати
                      </button>
                    </form>
                  </div>

                  {/* <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Закрити</button>
                    </div> */}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddGroupModalForm);
