import React, { Component } from 'react';
import NavTopBar from '../nav-top-bar/nav-top-bar';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import Loader from '../../components/Loader';
import AddTeacherModalForm from './add-teacher-modal-form';

const initial_state = {
  teachers: [],
  isLoading: false,
  error: null,
};

class TeachersPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial_state };
  }

  // handleChange(event, property) {
  //   return this.setState(stateSetter(property, event.target.value));
  // }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/teachers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('teachers', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    this.setState(
      stateSetter(
        'isAdmin',
        String(this.props.auth.login).toLowerCase() === 'admin'
      )
    );
  }

  handleDeleteTeacherDataButton(e) {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/teachers/${e.target.value}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        if (String(res.status).startsWith(2)) {
          window.location.reload(false);
        }

        if (String(res.status).startsWith(4)) {
          const data = await res.json();

          this.setState(stateSetter('error', data.message));
          this.setState(stateSetter('isLoading', false));
        }

        if (String(res.status).startsWith(5)) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }
  render() {
    const { teachers, isLoading, isAdmin, error } = this.state;

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <header>
            <NavTopBar auth={this.props.auth} />
          </header>
          <main className="vh-100">
            <div className="container pt-5 pb-3">
              <h3 className="pt-5 py-4 border-bottom">Усі вчителі</h3>
              {isAdmin ? (
                <AddTeacherModalForm token={this.props.auth.token} />
              ) : (
                <div></div>
              )}
              <div className="container py-4">
                <div>
                  {error && <p className="text-danger text-center">{error}</p>}
                </div>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Прізвище</th>
                      <th scope="col">Ім'я</th>
                      <th scope="col">По-батькові</th>
                      <th scope="col">Кафедра</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teachers
                      .filter(teacher => teacher.teacherId !== 'admin')
                      .map((teacher, i) => (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{teacher.lastName}</td>
                          <td>{teacher.firstName}</td>
                          <td>{teacher.middleName}</td>
                          <td>
                            {teacher.department
                              ? teacher.department.departmentName
                              : ''}
                          </td>
                          {isAdmin ? (
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger m-0"
                                value={teacher.teacherId}
                                onClick={e =>
                                  this.handleDeleteTeacherDataButton(e)
                                }
                              >
                                Видалити
                              </button>
                            </td>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default TeachersPage;
