import React, { Component } from 'react';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import Loader from '../../components/Loader';

const initial_state = {
  studentFirstName: '',
  studentLastName: '',
  studentMiddleName: '',
  studentCardId: '',
  studentGradeBookId: '',
  studentGroupId: null,
  groups: [],
  error: null,
  isLoading: false,
};

class AddStudentModalForm extends Component {
  constructor(props) {
    super(props);
    this.addStudentmodalRef = React.createRef();
    this.state = { ...initial_state };
  }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/groups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('groups', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  onSubmit = (event, isInvalid) => {
    const addStudentmodalNode = this.addStudentmodalRef.current;
    const hiddenAddStudentmodalNode = document.querySelector('.modal-backdrop');

    if (isInvalid) {
      this.setState({
        error: `Будь-ласка заповніть всі поля!`,
      });
    } else {
      this.setState(stateSetter('isLoading', true));

      const {
        studentFirstName,
        studentLastName,
        studentMiddleName,
        studentCardId,
        studentGradeBookId,
        studentGroupId,
      } = this.state;

      fetch(`${constants.BE_BASE_URL}/students`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        },
        body: JSON.stringify({
          firstName: studentFirstName,
          lastName: studentLastName,
          middleName: studentMiddleName,
          studentId: studentCardId,
          gradeBookId: studentGradeBookId,
          groupId: studentGroupId,
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            addStudentmodalNode.removeAttribute('aria-modal');
            addStudentmodalNode.setAttribute('aria-hidden', 'true');
            addStudentmodalNode.classList.remove('show');
            addStudentmodalNode.style.display = 'none';
            hiddenAddStudentmodalNode.classList.remove('show');
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(stateSetter('error', data.message));
          }

          if (String(res.status).startsWith(5)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
    event.preventDefault();
  };

  handleChange(event, property) {
    return this.setState(stateSetter(property, event.target.value));
  }

  render() {
    const {
      studentFirstName,
      studentLastName,
      studentMiddleName,
      studentCardId,
      studentGradeBookId,
      studentGroupId,
      groups,
      error,
      isLoading,
    } = this.state;

    const isInvalid =
      studentFirstName === '' ||
      studentLastName === '' ||
      studentMiddleName === '' ||
      studentCardId === '' ||
      studentGradeBookId === '';

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <div className="container ">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addStudentModal"
            >
              Додати студента
            </button>
            <div
              className="modal fade"
              id="addStudentModal"
              ref={this.addStudentmodalRef}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Заповніть форму даними про студента
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      className="justify-content-center"
                      onSubmit={e => this.onSubmit(e, isInvalid)}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={studentLastName}
                          placeholder="Прізвище"
                          onChange={event =>
                            this.handleChange(event, 'studentLastName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={studentFirstName}
                          placeholder="Ім'я"
                          onChange={event =>
                            this.handleChange(event, 'studentFirstName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={studentMiddleName}
                          placeholder="По-батькові"
                          onChange={event =>
                            this.handleChange(event, 'studentMiddleName')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={studentCardId}
                          placeholder="Номер студентського"
                          onChange={event =>
                            this.handleChange(event, 'studentCardId')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={studentGradeBookId}
                          placeholder="Номер залікової книжки"
                          onChange={event =>
                            this.handleChange(event, 'studentGradeBookId')
                          }
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          defaultValue={studentGroupId}
                          onChange={event =>
                            this.handleChange(event, 'studentGroupId')
                          }
                        >
                          <option value="" selected disabled>
                            Група
                          </option>
                          {groups.map(group => (
                            <option key={group.groupId} value={group.groupId}>
                              {group
                                ? `${group.groupName} - ${(function() {
                                    const educationYear =
                                      new Date().getUTCFullYear() -
                                      group.groupEducationStartYear;
                                    if (
                                      group.groupEducationType === 'bachelor'
                                    ) {
                                      return educationYear > 4
                                        ? 4
                                        : educationYear < 1
                                        ? 1
                                        : educationYear;
                                    }
                                    if (group.groupEducationType === 'master') {
                                      return educationYear > 2
                                        ? 2
                                        : educationYear < 1
                                        ? 1
                                        : educationYear;
                                    }
                                  })()}${group.groupNumber}`
                                : ''}{' '}
                              {group.groupEducationType === 'bachelor'
                                ? `${
                                    group.groupEducationStartYear
                                  } - ${group.groupEducationStartYear + 4}`
                                : `${
                                    group.groupEducationStartYear
                                  } - ${group.groupEducationStartYear + 2}`}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Додати
                      </button>
                    </form>
                  </div>

                  {/* <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Закрити</button>
                    </div> */}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddStudentModalForm;
