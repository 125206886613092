export default class AuthService {
  tokenName = 'token';
  login = 'login';

  loggedIn() {
    const token = this.getToken();
    const login = this.getLogin();

    return !!token && !!login;
  }

  setToken(token) {
    localStorage.setItem(this.tokenName, token);
  }

  getToken() {
    return localStorage.getItem(this.tokenName);
  }

  setLogin(login) {
    localStorage.setItem(this.login, login);
  }

  getLogin() {
    return localStorage.getItem(this.login);
  }

  logout() {
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem(this.login);
  }
}
