import React from 'react';
import { Link } from 'react-router-dom';
import AuthUserContext from '../authorization/auth-user.context';
import Button from '../../common/common.button';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';

const initial_state = {
  firstName: '',
  lastName: '',
  middleName: '',
  error: null,
  isLoading: false,
};
class NavTopBar extends React.Component {
  static contextType = AuthUserContext;

  constructor(props) {
    super(props);
    this.state = { ...initial_state };
  }

  logOutHandler = event => {
    this.context.logout();
    // window.location.reload(false);
  };

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/teachers/${this.props.auth.login}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('firstName', data.firstName));
          this.setState(stateSetter('lastName', data.lastName));
          this.setState(stateSetter('middleName', data.middleName));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  render() {
    const {
      firstName,
      lastName,
      middleName,
      // isLoading,
    } = this.state;

    return (
      <div id="topNavBar">
        {/* {isLoading ? <Loader /> : <div></div>} */}
        <nav className="navbar navbar-expand navbar-dark bg-dark py-15">
          <div className="container">
            <Link to="/main" className="navbar-brand">
              Головна
            </Link>

            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to="/my-reports" className="nav-link">
                    Мої відомості
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/groups" className="nav-link">
                    Групи
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/students" className="nav-link">
                    Студенти
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/teachers" className="nav-link">
                    Вчителі
                  </Link>
                </li>
              </ul>
              <div className="form-inline my-2">
                <div className="text-white mx-5">
                  {`${lastName} ${firstName} ${middleName}`}
                </div>
                <Button
                  onClick={this.logOutHandler}
                  className="btn btn-outline-secondary text-white"
                >
                  <span className="">Вийти</span>
                </Button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavTopBar;
