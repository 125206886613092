import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavTopBar from '../features/nav-top-bar/nav-top-bar';
import { stateSetter } from '../common/utils';
import constants from '../constants';
import Loader from '../components/Loader';

const initial_state = {
  reports: [],
  isLoading: false,
  error: null,
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initial_state };
  }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/subjects-reports`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('reports', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  handleReportCardClick(e, reportId) {
    this.props.history.push(`subjects-reports/${reportId}`);
  }

  render() {
    const { isLoading, reports } = this.state;

    return (
      <React.Fragment>
        <header>
          <NavTopBar auth={this.props.auth} />
        </header>
        <main>
          {isLoading ? <Loader /> : <div></div>}

          <div className="album py-5 bg-light">
            <div className="container pt-5">
              <h3 className="pb-4 mb-4 border-bottom">Усі відомості</h3>

              <div className="row">
                {reports.map((report, i) => (
                  <div className="col-md-4" key={i}>
                    <div
                      id="reportCard"
                      className="card mb-4 shadow-sm"
                      onClick={event =>
                        this.handleReportCardClick(event, report.reportId)
                      }
                    >
                      <div className="card-body">
                        <p className="card-text">
                          {report.department.departmentName}
                          <br />
                          {report.group
                            ? `${report.group.groupName} - ${(function() {
                                const educationYear =
                                  new Date().getUTCFullYear() -
                                  report.group.groupEducationStartYear;
                                if (
                                  report.group.groupEducationType === 'bachelor'
                                ) {
                                  return educationYear > 4
                                    ? 4
                                    : educationYear < 1
                                    ? 1
                                    : educationYear;
                                }
                                if (
                                  report.group.groupEducationType === 'master'
                                ) {
                                  return educationYear > 2
                                    ? 2
                                    : educationYear < 1
                                    ? 1
                                    : educationYear;
                                }
                              })()}${report.group.groupNumber}`
                            : ''}{' '}
                          {report.group.groupEducationType === 'bachelor'
                            ? `${
                                report.group.groupEducationStartYear
                              } - ${report.group.groupEducationStartYear + 4}`
                            : `${
                                report.group.groupEducationStartYear
                              } - ${report.group.groupEducationStartYear + 2}`}
                          <br />
                          {report.educationStartYear}р. -{' '}
                          {report.educationEndYear}р.
                          <br />
                          Семестр №{report.educationSemester}
                          <br />
                          {report.subjectName}{' '}
                          {report.reportType === 'zalik'
                            ? '(Залік)'
                            : '(Екзамен)'}
                          <br />
                          {report.mainTeacher.lastName}{' '}
                          {report.mainTeacher.firstName}{' '}
                          {report.mainTeacher.middleName} <br />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
