import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavTopBar from '../nav-top-bar/nav-top-bar';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import AddGroupModalForm from './add-group-modal-form';
import Loader from '../../components/Loader';

const initial_state = {
  groups: [],
  isLoading: false,
  isAdmin: false,
  error: null,
};

class GroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial_state };
  }

  // handleChange(event, property) {
  //   return this.setState(stateSetter(property, event.target.value));
  // }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/groups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('groups', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    this.setState(
      stateSetter(
        'isAdmin',
        String(this.props.auth.login).toLowerCase() === 'admin'
      )
    );
  }

  handleGroupCardClick(e, groupId) {
    this.props.history.push(`groups/${groupId}`);
  }

  render() {
    const { groups, isLoading, isAdmin } = this.state;

    return (
      <React.Fragment>
        <div>
          {isLoading ? <Loader /> : <div></div>}
          <header>
            <NavTopBar auth={this.props.auth} />
          </header>
          <main className="vh-100">
            <div className="container pt-5 pb-3">
              <h3 className="pt-5 py-4 border-bottom">Усі групи</h3>

              {isAdmin ? (
                <AddGroupModalForm token={this.props.auth.token} />
              ) : (
                <div></div>
              )}

              <div className="album py-4 bg-light">
                <div className="row">
                  {groups.map((group, i) => (
                    <div className="col-md-4" key={i}>
                      <div
                        id="groupCard"
                        className="card mb-4 shadow-sm"
                        onClick={event =>
                          this.handleGroupCardClick(event, group.groupId)
                        }
                      >
                        <div className="card-body">
                          <p className="card-text">
                            Група:{' '}
                            <b>
                              {group.groupName} -{' '}
                              {(function() {
                                const educationYear =
                                  new Date().getUTCFullYear() -
                                  group.groupEducationStartYear;
                                if (group.groupEducationType === 'bachelor') {
                                  return educationYear > 4
                                    ? 4
                                    : educationYear < 1
                                    ? 1
                                    : educationYear;
                                }
                                if (group.groupEducationType === 'master') {
                                  return educationYear > 2
                                    ? 2
                                    : educationYear < 1
                                    ? 1
                                    : educationYear;
                                }
                              })()}
                              {group.groupNumber}
                            </b>{' '}
                            <br />
                            Ступінь:{' '}
                            <b>
                              {group.groupEducationType === 'bachelor'
                                ? 'Бакалавр'
                                : 'Магістр'}
                            </b>{' '}
                            <br />
                            Роки навчання:{' '}
                            <b>
                              {group.groupEducationType === 'bachelor'
                                ? `${
                                    group.groupEducationStartYear
                                  } - ${group.groupEducationStartYear + 4}`
                                : `${
                                    group.groupEducationStartYear
                                  } - ${group.groupEducationStartYear + 2}`}
                            </b>
                            <br />
                            Статус навчання:{' '}
                            <b>
                              {(function() {
                                const currentYear = new Date().getUTCFullYear();
                                if (group.groupEducationType === 'bachelor') {
                                  return currentYear -
                                    group.groupEducationStartYear >
                                    4
                                    ? 'Завершено'
                                    : 'В процесі';
                                }
                                if (group.groupEducationType === 'master') {
                                  return currentYear -
                                    group.groupEducationStartYear >
                                    2
                                    ? 'Завершено'
                                    : 'В процесі';
                                }
                              })()}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GroupsPage);
