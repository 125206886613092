import React, { Component } from 'react';

class Button extends Component {

  componentDidMount() {
    const bodyTagNode = document.querySelector('body');

    bodyTagNode.style.overlay = 'hidden';
  }

  componentWillUnmount() {
    const bodyTagNode = document.querySelector('body');

    bodyTagNode.style.overlay = 'none';
  }

  render() {
    return (
      <button
        className={this.props.className}
        type="button"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

export default Button;
