import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="vw-100 vh-100 d-flex align-items-center justify-content-center flex-column text-center">
          <h2>Щось пішло не так. Оновіть сторінку.</h2>
        </div>
      );
    }

    return this.props.children;
  }
}
