import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavTopBar from '../nav-top-bar/nav-top-bar';
import { stateSetter } from '../../common/utils';
import constants from '../../constants';
import Loader from '../../components/Loader';

const initial_state = {
  group: null,
  assignedStudents: [],
  assignedStudentsIds: [],
  students: [],
  isLoading: false,
  isAdmin: false,
  error: null,
  saveError: null,
};

class GroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial_state };

    const currYear = new Date().getUTCFullYear();
    this.availableYears = Array(currYear - (currYear - 20))
      .fill('')
      .map((v, idx) => currYear - idx);
  }

  componentDidMount() {
    this.setState(stateSetter('isLoading', true));

    fetch(
      `${constants.BE_BASE_URL}/groups/${this.props.match.params.groupId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.token}`,
        },
      }
    )
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('group', data));
          this.setState(stateSetter('assignedStudents', data.students));
          this.setState(
            stateSetter(
              'assignedStudentsIds',
              data.students.map(student => student.studentId)
            )
          );
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    fetch(`${constants.BE_BASE_URL}/students`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('students', data));
          this.setState(stateSetter('isLoading', false));
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('error', 'Щось пішло не так. Оновіть сторінку.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });

    this.setState(
      stateSetter(
        'isAdmin',
        String(this.props.auth.login).toLowerCase() === 'admin'
      )
    );
  }

  handleDeleting(e) {
    this.setState(stateSetter('isLoading', true));

    fetch(`${constants.BE_BASE_URL}/groups/${this.state.group.groupId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then(async res => {
        if (String(res.status).startsWith(2)) {
          this.setState(stateSetter('isLoading', false));
          this.props.history.push(`/groups`);
        }

        if (
          String(res.status).startsWith(5) ||
          String(res.status).startsWith(4)
        ) {
          const data = await res.json();

          const message = data.message
            ? data.message
            : 'Щось пішло не так. Оновіть сторінку.';
          this.setState(stateSetter('isLoading', false));
          this.setState(stateSetter('error', message));
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('error', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  handleAddReportDataChange(dataType, value) {
    this.setState(
      stateSetter('addReportRecordData', {
        ...this.state.addReportRecordData,
        [dataType]: value === '' ? null : value,
      })
    );
  }

  handleAddStudentButton(e) {
    const addStudenToGroupSelectNode = document.querySelector(
      '#addStudenToGroupSelect'
    );

    const studentId = addStudenToGroupSelectNode.value;

    let isValid = true;

    if (studentId === '') {
      isValid = false;
      this.setState(stateSetter('error', 'Виберіть студента зі списку!'));
    }

    if (isValid) {
      this.setState(stateSetter('isLoading', true));

      const { group } = this.state;

      fetch(`${constants.BE_BASE_URL}/groups/${group.groupId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.token}`,
        },
        body: JSON.stringify({
          groupName: group.groupName,
          groupNumber: Number(group.groupNumber),
          groupEducationStartYear: Number(group.groupEducationStartYear),
          groupEducationType: group.groupEducationType,
          studentId: studentId,
          studentIdToBedeleted: null,
          reportsIds: group.reports.map(report => report.reportId),
        }),
      })
        .then(async res => {
          const data = await res.json();

          if (String(res.status).startsWith(2)) {
            window.location.reload(false);
          }

          if (String(res.status).startsWith(4)) {
            this.setState(stateSetter('saveError', data.message));
            this.setState(stateSetter('isLoading', false));
          }

          if (String(res.status).startsWith(5)) {
            this.setState(stateSetter('isLoading', false));
            this.setState(
              stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
            );
          }
        })
        .catch(err => {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
          );
        });
    }
  }

  handleDeleteStudentDataButton(e) {
    this.setState(stateSetter('isLoading', true));

    const toBeDeletedStudentId = e.target.value;

    const { group } = this.state;

    fetch(`${constants.BE_BASE_URL}/groups/${group.groupId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      body: JSON.stringify({
        groupName: group.groupName,
        groupNumber: Number(group.groupNumber),
        groupEducationStartYear: Number(group.groupEducationStartYear),
        groupEducationType: group.groupEducationType,
        studentId: null,
        studentIdToBedeleted: toBeDeletedStudentId,
        reportsIds: group.reports.map(report => report.reportId),
      }),
    })
      .then(async res => {
        const data = await res.json();

        if (String(res.status).startsWith(2)) {
          window.location.reload(false);
        }

        if (String(res.status).startsWith(4)) {
          this.setState(stateSetter('saveError', data.message));
          this.setState(stateSetter('isLoading', false));
        }

        if (String(res.status).startsWith(5)) {
          this.setState(stateSetter('isLoading', false));
          this.setState(
            stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
          );
        }
      })
      .catch(err => {
        this.setState(stateSetter('isLoading', false));
        this.setState(
          stateSetter('saveError', 'Щось пішло не так. Спробуйте знову.')
        );
      });
  }

  onKeyDownHandler(e) {
    e.target.style.width = (e.target.value.length + 1) * 8.7 + 'px !important';
  }

  render() {
    const {
      group,
      students,
      assignedStudents,
      assignedStudentsIds,
      isLoading,
      isAdmin,
      error,
      saveError,
    } = this.state;

    return (
      <React.Fragment>
        {isLoading ? <Loader /> : <div></div>}

        <header>
          <NavTopBar auth={this.props.auth} />
        </header>
        <main>
          <div className="container mt-5 pt-5">
            <h3 className="pb-4 border-bottom">
              Група{' '}
              {group
                ? `${group.groupName} - ${(function() {
                    const educationYear =
                      new Date().getUTCFullYear() -
                      group.groupEducationStartYear;
                    if (group.groupEducationType === 'bachelor') {
                      return educationYear > 4
                        ? 4
                        : educationYear < 1
                        ? 1
                        : educationYear;
                    }
                    if (group.groupEducationType === 'master') {
                      return educationYear > 2
                        ? 2
                        : educationYear < 1
                        ? 1
                        : educationYear;
                    }
                  })()}${group.groupNumber}`
                : ''}
            </h3>

            {isAdmin ? (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-danger ml-4 mt-3"
                  value="isReportMarksEditable"
                  onClick={event => this.handleDeleting(event)}
                >
                  Видалити групу
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {group ? (
              <form className="mt-3 form" style={{ fontSize: '1.1em' }}>
                Ступінь:{' '}
                <b>
                  {group.groupEducationType === 'bachelor'
                    ? 'Бакалавр'
                    : 'Магістр'}
                </b>{' '}
                <br />
                Роки навчання:{' '}
                <b>
                  {group.groupEducationType === 'bachelor'
                    ? `${
                        group.groupEducationStartYear
                      } - ${group.groupEducationStartYear + 4}`
                    : `${
                        group.groupEducationStartYear
                      } - ${group.groupEducationStartYear + 2}`}
                </b>
                <br />
                Статус навчання:{' '}
                <b>
                  {(function() {
                    const currentYear = new Date().getUTCFullYear();
                    if (group.groupEducationType === 'bachelor') {
                      return currentYear - group.groupEducationStartYear > 4
                        ? 'Завершено'
                        : 'В процесі';
                    }
                    if (group.groupEducationType === 'master') {
                      return currentYear - group.groupEducationStartYear > 2
                        ? 'Завершено'
                        : 'В процесі';
                    }
                  })()}
                </b>
              </form>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <div className="text-center">
              {saveError && <p className="text-danger">{saveError}</p>}
            </div>

            <form>
              <table className="table mt-3 table-bordered text-center">
                <thead>
                  <tr>
                    <th className="align-middle" scope="col">
                      №
                    </th>
                    <th className="align-middle" scope="col">
                      Студент
                    </th>
                    {isAdmin ? (
                      <th className="align-middle" scope="col"></th>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {assignedStudents.length ? (
                    assignedStudents
                      .sort((a, b) => {
                        if (a.lastName < b.lastName) {
                          return -1;
                        }
                        if (a.lastName > b.lastName) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((student, index) => {
                        return (
                          <tr key={student.studentId}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">
                              {`${student.lastName} ${student.firstName} ${student.middleName}`}
                            </td>
                            {isAdmin ? (
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger m-0"
                                  value={student.studentId}
                                  onClick={e =>
                                    this.handleDeleteStudentDataButton(e)
                                  }
                                >
                                  Видалити
                                </button>
                              </td>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                        );
                      })
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {isAdmin ? (
                    <tr>
                      <td className="align-middle"></td>
                      <td className="align-middle">
                        <select
                          className="form-control"
                          id="addStudenToGroupSelect"
                          onChange={e =>
                            this.handleAddReportDataChange(
                              'studentId',
                              e.target.value
                            )
                          }
                        >
                          <option value="" default>
                            Виберіть студента
                          </option>

                          {students
                            .filter(
                              student =>
                                !assignedStudentsIds.includes(student.studentId)
                            )
                            .map(student => (
                              <option
                                key={student.studentId}
                                value={student.studentId}
                              >
                                {`${student.lastName} ${student.firstName} ${student.middleName}`}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td>
                        <button
                          type="button"
                          className="btn btn-success m-0"
                          onClick={e => this.handleAddStudentButton(e)}
                        >
                          Додати
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </tbody>
              </table>
              <div className="text-center">
                {error && <p className="text-danger">{error}</p>}
              </div>
            </form>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(GroupPage);
